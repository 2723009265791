import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import bargeTracking from "../../assets/features/barge-tracking.png";
import dataAnalytics from "../../assets/features/data-analytic.png";
import waterLevels from "../../assets/features/whater-levels.png";
import freightRates from "../../assets/features/freight-rates.png";
import terminalData from "../../assets/features/terminal-data.png";
import portData from "../../assets/features/port-data.png";

const features = [
  {
    titleKey: "bottom_feature_barge_tracking",
    image: bargeTracking,
    url: "/barge-tracking",
  },
  {
    titleKey: "bottom_feature_data_analytics",
    image: dataAnalytics,
    url: "/data-analytics",
  },
  {
    titleKey: "bottom_feature_waterlevels",
    image: waterLevels,
    url: "/rhine-waterlevels",
  },
  {
    titleKey: "bottom_feature_freight_rates",
    image: freightRates,
    url: "/freight-rates",
  },
  {
    titleKey: "bottom_feature_terminal_data",
    image: terminalData,
    url: "/terminal-refinery-data",
  },
  {
    titleKey: "bottom_feature_port_data",
    image: portData,
    url: "/port-refinery-utilisation",
  },
];

const commitments = [
  {
    headerKey: "bottom_commitment_accurate_title",
    subtextKey: "bottom_commitment_accurate_desc",
  },
  {
    headerKey: "bottom_commitment_realtime_title",
    subtextKey: "bottom_commitment_realtime_desc",
  },
  {
    headerKey: "bottom_commitment_expertise_title",
    subtextKey: "bottom_commitment_expertise_desc",
  },
];

const BottomSection = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Our commitment section */}
      <Box sx={{ py: { xs: 8, md: 12 }, bgcolor: "#f8f9fa" }}>
        <Container maxWidth="lg">
          <Box sx={{ textAlign: "center", mb: { xs: 6, md: 8 } }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "28px", md: "36px" },
                fontWeight: 600,
                color: "#001557",
                mb: 2,
              }}
            >
              {t("bottom_commitment_title")}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "18px" },
                color: "#666",
                maxWidth: "700px",
                mx: "auto",
              }}
            >
              {t("bottom_commitment_subtitle")}
            </Typography>
          </Box>

          <Grid container spacing={4}>
            {commitments.map((item, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box
                  sx={{
                    height: "100%",
                    p: 3,
                    borderRadius: 2,
                    bgcolor: "#fff",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: "0 8px 24px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <CircleIcon
                      sx={{ color: "#51f3d2", fontSize: 12, mr: 1.5 }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: "18px", md: "20px" },
                        fontWeight: 600,
                        color: "#001557",
                      }}
                    >
                      {t(item.headerKey)}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: 1.6,
                      color: "#666",
                    }}
                  >
                    {t(item.subtextKey)}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Features section */}
      <Box sx={{ py: { xs: 10, md: 14 }, bgcolor: "#fff" }}>
        <Container maxWidth="lg">
          <Box sx={{ mb: 10, textAlign: "center" }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "32px", md: "42px" },
                fontWeight: 700,
                color: "#001557",
                mb: 2,
              }}
            >
              {t("bottom_features_title")}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                color: "#666",
                maxWidth: "600px",
                mx: "auto",
              }}
            >
              {t("bottom_features_subtitle")}
            </Typography>
          </Box>

          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  component={Link}
                  to={feature.url}
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    textDecoration: "none",
                    borderRadius: 3,
                    overflow: "hidden",
                    bgcolor: "#fff",
                    boxShadow:
                      "rgba(0, 21, 87, 0.04) 0px 2px 4px, rgba(0, 21, 87, 0.02) 0px 8px 16px",
                    height: "100%",
                    transition: "all 0.3s ease",
                    border: "1px solid rgba(0, 21, 87, 0.06)",
                    "&:hover": {
                      transform: "translateY(-8px)",
                      boxShadow:
                        "rgba(0, 21, 87, 0.12) 0px 8px 24px, rgba(0, 21, 87, 0.02) 0px 16px 32px",
                      borderColor: "#51f3d2",
                      "& .feature-image": {
                        transform: "scale(1.05)",
                      },
                      "& .feature-arrow": {
                        transform: "translateX(4px)",
                        bgcolor: "#51f3d2",
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      height: 260,
                      bgcolor: "rgba(0, 21, 87, 0.02)",
                      overflow: "hidden",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={feature.image}
                      alt={t(feature.titleKey)}
                      className="feature-image"
                      sx={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        transition:
                          "transform 0.6s cubic-bezier(0.4, 0, 0.2, 1)",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      p: 4,
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      zIndex: 1,
                      bgcolor: "#fff",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: { xs: "20px", md: "24px" },
                        fontWeight: 600,
                        color: "#001557",
                        mb: 3,
                        lineHeight: 1.3,
                      }}
                    >
                      {t(feature.titleKey)}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        pt: 3,
                        mt: "auto",
                        borderTop: "1px solid rgba(0, 21, 87, 0.08)",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#001557",
                        }}
                      >
                        {t("bottom_feature_explore")}
                      </Typography>
                      <Box
                        className="feature-arrow"
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: "rgba(81,243,210,0.1)",
                          color: "#001557",
                          transition: "all 0.3s ease",
                        }}
                      >
                        →
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default BottomSection;
