import React, { useEffect } from "react";
import { Box, Fade } from "@mui/material";
import TopSection from "./TopSection";
import MiddleSection from "./MiddleSection";
import Footer from "../common/Footer";
import BottomSection from "./BottomSection";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "../GeneralErrorPage";

function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true} timeout={500}>
      <Box sx={{ bgcolor: "#fff" }}>
        <TopSection />
        <MiddleSection />
        <BottomSection />
        <Footer />
      </Box>
    </Fade>
  );
}

export default withErrorBoundary(Homepage, {
  fallback: <GeneralErrorPage />,
});
