import React, { lazy, Suspense, useContext, useState, useEffect } from "react";
import {
  Grid,
  useTheme,
  useMediaQuery,
  AppBar,
  IconButton,
  Toolbar,
  CircularProgress,
  Box,
  Fade,
  Zoom,
  Fab,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import TopNavBar from "./components/common/TopNavBar";
import Sidebar from "./components/common/sidebar/Sidebar";
import Homepage from "./components/homepage/Homepage";
import LoginSuccess from "./components/LoginSuccess";
import ConfirmQuote from "./components/ConfirmQuote";
import UserSettings from "./components/UserSettings";
import RoleGuard from "./components/guards/RoleGuard";
import { UserContext } from "./components/AuthGuard";
import logo from "./assets/logo-alternated.png";
import ToReadMore from "./components/ToReadMore";
import { LoggedInRouter } from "./LoggedInRouter";

/**
 * Lazy load components to improve performance and reduce initial bundle size
 * this components are not needed on the first load or are not used by all users
 */
const SignInScreen = lazy(() => import("./components/SignInScreen"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const About = lazy(() => import("./components/About"));
const PublicMarketNews = lazy(() => import("./components/PublicMarketNews"));
const NewsPost = lazy(() => import("./components/NewsPost"));
const BargeTracking = lazy(() => import("./components/features/BargeTracking"));
const DataAnalytics = lazy(() => import("./components/features/DataAnalytics"));
const RhineWaterlevels = lazy(() =>
  import("./components/features/RhineWaterlevels"),
);
const FreightRates = lazy(() => import("./components/features/FreightRates"));
const TerminalRefineryData = lazy(() =>
  import("./components/features/TerminalRefineryData"),
);
const PortRefineryUtilisation = lazy(() =>
  import("./components/features/PortRefineryUtilisation"),
);
const CustomSolutions = lazy(() =>
  import("./components/features/CustomSolutions"),
);
const OperationsBlending = lazy(() =>
  import("./components/users/OperationsBlending"),
);
const TradersAnalysts = lazy(() =>
  import("./components/users/TradersAnalysts"),
);
const Charterers = lazy(() => import("./components/users/Charterers"));
const BanksFinancial = lazy(() => import("./components/users/BanksFinancial"));
const Contact = lazy(() => import("./components/Contact"));
const Careers = lazy(() => import("./components/Careers"));
const Marketing = lazy(() => import("./components/Marketing"));

const generalRoutes = [
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/barge-tracking",
    element: <BargeTracking />,
  },
  {
    path: "/data-analytics",
    element: <DataAnalytics />,
  },
  {
    path: "/rhine-waterlevels",
    element: <RhineWaterlevels />,
  },
  {
    path: "/freight-rates",
    element: <FreightRates />,
  },
  {
    path: "/terminal-refinery-data",
    element: <TerminalRefineryData />,
  },
  {
    path: "/port-refinery-utilisation",
    element: <PortRefineryUtilisation />,
  },
  {
    path: "/custom-solutions",
    element: <CustomSolutions />,
  },
  {
    path: "/operations-blending",
    element: <OperationsBlending />,
  },
  {
    path: "/traders-analysts",
    element: <TradersAnalysts />,
  },
  {
    path: "/charterers",
    element: <Charterers />,
  },
  {
    path: "/banks-financial",
    element: <BanksFinancial />,
  },
  {
    path: "/login",
    element: <SignInScreen />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/careers",
    element: <Careers />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/news",
    element: <PublicMarketNews />,
  },
  {
    path: "/market-news",
    element: <ToReadMore />,
  },
  {
    path: "/news-post/:id",
    element: <NewsPost />,
  },
  {
    path: "/verify/:token/:accept",
    element: <ConfirmQuote />,
  },
  {
    path: "/login-success",
    element: (
      <RoleGuard roles={["admin", "write", "read", "none"]}>
        <LoginSuccess />
      </RoleGuard>
    ),
  },
  {
    path: "/user",
    element: (
      <RoleGuard roles={["admin", "write", "read", "none"]}>
        <UserSettings isNotVerified={true} />
      </RoleGuard>
    ),
  },
  {
    path: "/marketing",
    element: <Marketing />,
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
];

const StyledRoot = styled(Grid)(({}) => ({
  display: "grid",
  maxHeight: `${window.innerHeight}px`,
  height: `${window.innerHeight}px`,
}));

const StyledMain = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "matches",
})(({ matches }) => ({
  padding:
    window.location.pathname.substring(1) === "ship-tracking" ? 0 : "25px",
  minHeight: `${window.innerHeight}px`,
  overflowY: "auto",
  overflowX: "hidden",
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  "& img": {
    height: "28px",
    cursor: "pointer",
    marginRight: "16px",
    filter: "drop-shadow(0 0 0.5px rgba(0, 0, 0, 0.3))",
    transition: "all 0.3s ease",
    "&:hover": {
      filter: "drop-shadow(0 0 0.5px rgba(0, 0, 0, 0.4))",
    },
  },
}));

const UnauthenticatedContainer = styled("div")({
  marginTop: "56px",
});

const LoadingScreen = () => (
  <Fade in timeout={300}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        background: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(4px)",
        zIndex: 1000,
      }}
    >
      <CircularProgress />
    </Box>
  </Fade>
);

const ScrollTopButton = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: 32,
  right: 32,
  backgroundColor: "#51f3d2",
  color: "#0a2540",
  "&:hover": {
    backgroundColor: "#3dd1b0",
  },
  zIndex: 1000,
}));

const RenderUnauthScreens = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.scrollY > 400) {
        setShowScroll(true);
      } else if (showScroll && window.scrollY <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [showScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {window.location.pathname !== "/signin" && <TopNavBar />}
      <UnauthenticatedContainer
        sx={{
          marginTop: window.location.pathname === "/signin" ? 0 : "56px",
        }}
      >
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            {generalRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Routes>
        </Suspense>
      </UnauthenticatedContainer>
      <Zoom in={showScroll}>
        <ScrollTopButton
          onClick={scrollToTop}
          size="medium"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </ScrollTopButton>
      </Zoom>
    </>
  );
};

const RootWithSidebar = ({
  children,
  sidebarOpen,
  matches,
  handleSidebarOpen,
  handleMobileOpen,
  mobileOpen,
}) => {
  return (
    <StyledRoot
      open={sidebarOpen}
      gridTemplateColumns={
        matches ? (sidebarOpen ? "250px 1fr" : "95px 1fr") : "12fr"
      }
    >
      {!matches && (
        <AppBar
          sx={{
            backgroundColor: "#fff",
            borderBottom: "1px solid #e8e8e8",
            color: "#333",
            boxShadow: "none",
            display: {
              xs: "block",
              md: "none",
            },
          }}
        >
          <Toolbar
            sx={{
              flexWrap: "nowrap",
              justifyContent: "space-between",
              padding: "4px 24px",
              minHeight: "56px",
            }}
          >
            <LogoWrapper>
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "28px",
                  cursor: "pointer",
                  marginRight: "16px",
                  filter: "drop-shadow(0 0 0.5px rgba(0, 0, 0, 0.3))",
                  transition: "all 0.3s ease",
                }}
              />
            </LogoWrapper>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
              onClick={handleMobileOpen}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      {(matches || mobileOpen) && (
        <Sidebar
          matches={matches}
          mobileOpen={mobileOpen}
          large={sidebarOpen}
          handleOpen={handleSidebarOpen}
          handleMobileOpen={handleMobileOpen}
        />
      )}
      {children}
    </StyledRoot>
  );
};

const Main = () => {
  const [user] = useContext(UserContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(() =>
    !matches ? true : localStorage.getItem("sidebarOpen") === "true",
  );

  if (!user || !user.role || user.role === "none")
    return <RenderUnauthScreens />;

  const handleSidebarOpen = () => {
    const opened = !sidebarOpen;
    setSidebarOpen(opened);
    localStorage.setItem("sidebarOpen", opened);
  };

  const handleMobileOpen = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <RootWithSidebar
      sidebarOpen={sidebarOpen}
      matches={matches}
      handleSidebarOpen={handleSidebarOpen}
      handleMobileOpen={handleMobileOpen}
      mobileOpen={mobileOpen}
    >
      <Suspense fallback={<LoadingScreen />}>
        <StyledMain matches={matches}>
          <LoggedInRouter />
        </StyledMain>
      </Suspense>
    </RootWithSidebar>
  );
};

export default Main;
