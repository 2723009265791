import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  CircularProgress,
  IconButton,
  Drawer,
  List,
  ListItem,
  Chip,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import LanguageIcon from "@mui/icons-material/Language";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import WaterIcon from "@mui/icons-material/Water";
import PaidIcon from "@mui/icons-material/Paid";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BuildIcon from "@mui/icons-material/Build";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BusinessIcon from "@mui/icons-material/Business";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Flag from "react-world-flags";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import logo from "../../assets/logo-alternated.png";
import { UserContext } from "../AuthGuard";
import { signout } from "./sidebar/Sidebar";

const StyledMenuItem = styled(Typography)(({ theme, item }) => ({
  fontWeight:
    item.url === window.location.pathname.substring(1) ? "600" : "600",
  textDecorationLine: "none",
  marginLeft: theme.spacing(2),
  color: "#001557",
  cursor: "pointer",
  fontSize: "14px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  gap: "4px",
  padding: "8px 0",
  borderRadius: item.outline ? "4px" : null,
  border: item.outline ? "1px solid #51f3d2" : null,
  padding: item.outline ? "8px 24px" : null,
  backgroundColor: item.outline ? "#51f3d2" : "transparent",
  color: item.outline ? "#001557" : "#001557",
  transition: "all 0.3s ease",
  ...(item.className === "last-item" && {
    marginRight: theme.spacing(2),
  }),
  "&:hover": {
    color: "#001557",
    backgroundColor: item.outline ? "#51f3d2" : "transparent",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: -4,
    left: 0,
    width: item.url === window.location.pathname.substring(1) ? "100%" : 0,
    height: "3px",
    backgroundColor: "#51f3d2",
    transition: "width 0.3s ease",
  },
  "&:hover::after": {
    width: item.dropdownItems || item.outline ? 0 : "100%",
  },
}));

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledArrowIcon = styled("div")(({ isOpen }) => ({
  display: "flex",
  alignItems: "center",
  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
  transition: "transform 0.3s ease",
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  "& img": {
    height: "28px",
    cursor: "pointer",
    marginRight: "16px",
    filter: "drop-shadow(0 0 0.5px rgba(0, 0, 0, 0.3))",
    transition: "all 0.3s ease",
    "&:hover": {
      filter: "drop-shadow(0 0 0.5px rgba(0, 0, 0, 0.4))",
    },
  },
}));

const TopNavBar = ({ hasBackground = false }) => {
  const [user, setUser] = useContext(UserContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("language") || "en",
  );

  useEffect(() => setLoading(!user), [user]);

  useEffect(() => {
    let ticking = false;
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const scrollPosition = window.pageYOffset;
          if (scrollPosition > 0 !== isScrolled) {
            setIsScrolled(scrollPosition > 0);
          }
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolled]);

  const handleMenuOpen = (event, dropdownName) => {
    setActiveDropdown(dropdownName);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setActiveDropdown(null);
  };

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    setCurrentLanguage(lng);
    i18n.changeLanguage(lng);
    setDrawerOpen(false);
  };

  const menuItems = getMenuItems(t, changeLanguage, currentLanguage);

  if (user?.role === "none") {
    menuItems.push({
      name: t("menu_logout"),
      action: () => signout(setUser, navigate),
      outline: true,
    });
  }

  const handleDrawerItemClick = (item) => {
    if (item.dropdownItems) {
      setOpenDropdowns((prev) => ({
        ...prev,
        [item.name]: !prev[item.name],
      }));
      return;
    }

    setDrawerOpen(false);
    if (item.action) {
      item.action();
    } else if (item.url) {
      navigate(item.url === "/" ? "/" : `/${item.url}`);
    }
  };

  const handleDropdownItemClick = (dropdownItem) => {
    setDrawerOpen(false);
    if (dropdownItem.action) {
      dropdownItem.action();
    } else if (dropdownItem.url) {
      navigate(`/${dropdownItem.url}`);
    }
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: "#fff",
        borderBottom: "1px solid #e8e8e8",
        color: "#333",
        paddingRight: "0 !important",
        boxShadow: isScrolled ? "0 2px 8px rgba(0,0,0,0.1)" : "none",
        transition: "box-shadow 0.3s ease",
      }}
    >
      <Toolbar
        sx={{
          flexWrap: "nowrap",
          justifyContent: "space-between",
          maxWidth: "1200px",
          width: "100%",
          margin: "0 auto",
          padding: "4px 24px",
          minHeight: "56px",
          paddingRight: "calc(24px + 17px)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LogoWrapper>
            <img
              src={logo}
              alt="logo"
              onClick={() => navigate("/")}
              style={{
                height: "28px",
                cursor: "pointer",
                marginRight: "16px",
              }}
            />
          </LogoWrapper>
        </Box>

        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{
            display: { xs: "block", md: "none" },
          }}
          onClick={() => setDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            gap: 2,
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            menuItems
              .filter((item) => !item.role || item.role.includes(user?.role))
              .map((item) =>
                renderMenuItem(
                  item,
                  handleMenuOpen,
                  handleMenuClose,
                  () => setDrawerOpen(false),
                  activeDropdown,
                  anchorEl,
                  user,
                  t,
                ),
              )
          )}
        </Box>
      </Toolbar>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setOpenDropdowns({});
        }}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: "280px" },
            backgroundColor: "#fff",
            top: "56px",
            height: "calc(100% - 56px)",
            borderTop: "none",
          },
        }}
        sx={{
          "& .MuiBackdrop-root": {
            top: "56px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
        ModalProps={{
          keepMounted: true,
          disablePortal: true,
          disableScrollLock: false,
        }}
      >
        <Box sx={{ p: 2, height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mb: 2,
              borderBottom: "1px solid #e8e8e8",
              pb: 2,
            }}
          >
            <IconButton onClick={() => setDrawerOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <List sx={{ height: "calc(100% - 64px)", overflowY: "auto" }}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              menuItems
                .filter((item) => !item.role || item.role.includes(user?.role))
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      onClick={() => handleDrawerItemClick(item)}
                      sx={{
                        color: "#001557",
                        py: 2,
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#001557",
                        }}
                      >
                        {item.name}
                      </Typography>
                      {item.dropdownItems && (
                        <Box
                          sx={{
                            ml: "auto",
                            color: "#001557",
                            display: "flex",
                            alignItems: "center",
                            transform: openDropdowns[item.name]
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s ease",
                          }}
                        >
                          <ArrowDropDown />
                        </Box>
                      )}
                    </ListItem>
                    {item.dropdownItems && openDropdowns[item.name] && (
                      <List sx={{ pl: 2 }}>
                        {item.dropdownItems.map(
                          (dropdownItem, dropdownIndex) => (
                            <ListItem
                              key={dropdownIndex}
                              onClick={() =>
                                handleDropdownItemClick(dropdownItem)
                              }
                              sx={{
                                color: "#001557",
                                py: 1.5,
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  color: "#001557",
                                }}
                              >
                                {dropdownItem.name}
                              </Typography>
                            </ListItem>
                          ),
                        )}
                      </List>
                    )}
                    {index < menuItems.length - 1 && <Divider />}
                  </React.Fragment>
                ))
            )}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

const getMenuItems = (t, changeLanguage, currentLanguage) => {
  const languageFlags = {
    en: "GB",
    nl: "NL",
    fr: "FR",
    de: "DE",
  };

  const FlagIcon = ({ code }) => (
    <Box
      sx={{
        width: "20px",
        height: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "2px",
          boxShadow: "0 0 2px rgba(0,0,0,0.2)",
        },
      }}
    >
      <Flag code={code} />
    </Box>
  );

  const itemsForGuest = [
    { name: t("menu_home"), menu_key: "menu_home", url: "/", mobileOnly: true },
    {
      name: t("menu_features"),
      menu_key: "menu_features",
      dropdownItems: [
        {
          name: t("menu_features_barge"),
          menu_key: "menu_features_barge",
          url: "barge-tracking",
          icon: <DirectionsBoatFilledIcon />,
          description: t("menu_features_barge_desc"),
        },
        {
          name: t("menu_features_analytics"),
          menu_key: "menu_features_analytics",
          url: "data-analytics",
          icon: <AnalyticsIcon />,
          description: t("menu_features_analytics_desc"),
        },
        {
          name: t("menu_features_rhine"),
          menu_key: "menu_features_rhine",
          url: "rhine-waterlevels",
          icon: <WaterIcon />,
          description: t("menu_features_rhine_desc"),
        },
        {
          name: t("menu_features_freight"),
          menu_key: "menu_features_freight",
          url: "freight-rates",
          icon: <PaidIcon />,
          description: t("menu_features_freight_desc"),
        },
        {
          name: t("menu_features_terminal"),
          menu_key: "menu_features_terminal",
          url: "terminal-refinery-data",
          icon: <WarehouseIcon />,
          description: t("menu_features_terminal_desc"),
        },
        {
          name: t("menu_features_port"),
          menu_key: "menu_features_port",
          url: "port-refinery-utilisation",
          icon: <AccountTreeIcon />,
          description: t("menu_features_port_desc"),
        },
        {
          name: t("menu_features_custom"),
          menu_key: "menu_features_custom",
          url: "custom-solutions",
          icon: <BuildIcon />,
          description: t("menu_features_custom_desc"),
        },
      ],
    },
    {
      name: t("menu_users"),
      menu_key: "menu_users",
      dropdownItems: [
        {
          name: t("menu_users_charterers"),
          menu_key: "menu_users_charterers",
          url: "charterers",
          icon: <DirectionsBoatIcon />,
          description: t("menu_users_charterers_desc"),
        },
        {
          name: t("menu_users_banks"),
          menu_key: "menu_users_banks",
          url: "banks-financial",
          icon: <AccountBalanceIcon />,
          description: t("menu_users_banks_desc"),
        },
        {
          name: t("menu_users_traders"),
          menu_key: "menu_users_traders",
          url: "traders-analysts",
          icon: <ShowChartIcon />,
          description: t("menu_users_traders_desc"),
        },
        {
          name: t("menu_users_operations"),
          menu_key: "menu_users_operations",
          url: "operations-blending",
          icon: <EngineeringIcon />,
          description: t("menu_users_operations_desc"),
        },
      ],
    },
    { name: t("menu_about"), menu_key: "menu_about", url: "about" },
    { name: t("menu_resources"), menu_key: "menu_resources", url: "news" },
    {
      name: t("menu_language"),
      menu_key: "menu_language",
      icon: <FlagIcon code={languageFlags[currentLanguage]} />,
      dropdownItems: [
        {
          name: t("menu_language_en"),
          menu_key: "menu_language_en",
          action: () => changeLanguage("en"),
          icon: <FlagIcon code="GB" />,
        },
        {
          name: t("menu_language_nl"),
          menu_key: "menu_language_nl",
          action: () => changeLanguage("nl"),
          icon: <FlagIcon code="NL" />,
        },
        {
          name: t("menu_language_fr"),
          menu_key: "menu_language_fr",
          action: () => changeLanguage("fr"),
          icon: <FlagIcon code="FR" />,
        },
        {
          name: t("menu_language_de"),
          menu_key: "menu_language_de",
          action: () => changeLanguage("de"),
          icon: <FlagIcon code="DE" />,
        },
      ],
      className: "last-item",
    },
    {
      name: t("menu_login"),
      menu_key: "menu_login",
      url: "login",
      outline: true,
    },
  ];

  return itemsForGuest;
};

const getAppBarStyle = (hasBackground) => {
  const path = window.location.pathname.split("/")[1];
  const transparentPaths = ["", "team", "about", "news", "news-post"];
  return {
    background: transparentPaths.includes(path)
      ? "transparent"
      : "linear-gradient(100deg, rgb(0, 18, 85) 50%, rgb(115 221 201) 100%)",
    boxShadow: "none",
    position: "absolute",
  };
};

const getLogoStyle = () => ({
  cursor: "pointer",
  userSelect: "none",
  marginTop: "24px",
  marginBottom: "24px",
  maxWidth: "200px",
  margin: "16px 0px 16px 0px",
});

const renderMenuItem = (
  item,
  handleMenuOpen,
  handleMenuClose,
  closeDrawer,
  activeDropdown,
  anchorEl,
  user,
  t,
) => {
  if (item.mobileOnly) {
    return null;
  }

  const isDropdown = item.dropdownItems && item.dropdownItems.length > 0;

  const handleItemClick = () => {
    if (item.action) {
      item.action();
    }
    closeDrawer();
  };

  return (
    <Box
      key={item.name}
      onMouseEnter={
        isDropdown ? (event) => handleMenuOpen(event, item.name) : null
      }
      onMouseLeave={isDropdown ? handleMenuClose : null}
      sx={{ position: "relative", m: 0.5 }}
    >
      <StyledMenuItem
        variant="body1"
        component={isDropdown ? "div" : Link}
        to={
          isDropdown
            ? null
            : item.url === "/"
            ? "/"
            : "/" + getUrlFromName(item)
        }
        onClick={handleItemClick}
        item={item}
      >
        {item.icon || item.name}
        {isDropdown && (
          <StyledArrowIcon isOpen={activeDropdown === item.name}>
            <ArrowDropDown />
          </StyledArrowIcon>
        )}
      </StyledMenuItem>
      {isDropdown &&
        renderDropdownMenu(
          item,
          handleMenuClose,
          activeDropdown,
          anchorEl,
          user,
        )}
    </Box>
  );
};

const getUrlFromName = (item) => {
  if (item.url === "/") return "/";
  return item.url ? item.url : item.name.toLowerCase().replace(" ", "-");
};

const renderDropdownMenu = (
  item,
  handleMenuClose,
  activeDropdown,
  anchorEl,
  user,
) => {
  const isFeatureOrUser =
    item.menu_key === "menu_features" || item.menu_key === "menu_users";

  return (
    <Menu
      anchorEl={anchorEl}
      open={activeDropdown === item.name}
      onClose={handleMenuClose}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        width: "fit-content",
        height: "auto",
        "& .MuiPaper-root": {
          marginTop: "4px",
        },
      }}
      disableRestoreFocus
      hideBackdrop
      slotProps={{
        paper: {
          elevation: 3,
          sx: {
            backgroundColor: "#fff",
            borderRadius: "4px",
            minWidth: isFeatureOrUser ? "480px" : "200px",
            pl: "10px",
            pr: "10px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
            "& .MuiMenuItem-root": {
              padding: isFeatureOrUser ? "12px" : "8px 16px",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
              "& + div > .MuiMenuItem-root": {
                marginTop: "4px",
              },
            },
          },
        },
      }}
      disableScrollLock={true}
    >
      {item.dropdownItems
        .filter((subItem) => !subItem.role || subItem.role.includes(user?.role))
        .map((dropdownItem) => (
          <div key={dropdownItem.name}>
            {dropdownItem.hasDividerTop && <Divider />}
            <MenuItem
              key={dropdownItem.name}
              component={Link}
              to={
                dropdownItem.action || dropdownItem.link
                  ? null
                  : "/" + getUrlFromName(dropdownItem)
              }
              color="warning"
              onClick={
                dropdownItem.link
                  ? () => {
                      window.open(dropdownItem.link, "_blank").focus();
                    }
                  : () => {
                      if (dropdownItem.action) {
                        dropdownItem.action();
                      }
                      handleMenuClose();
                    }
              }
              sx={{
                borderRadius: "5px",
                color: dropdownItem.color ? dropdownItem.color : "inherit",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
                padding: item.name === "language" ? "8px 16px" : "16px",
                "& .flag": {
                  boxShadow: "0 0 1px rgba(0,0,0,0.2)",
                  borderRadius: "2px",
                  width: "24px",
                  height: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              {dropdownItem.icon && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: isFeatureOrUser ? "primary.main" : "inherit",
                    width: item.name === "language" ? "24px" : "auto",
                    justifyContent: "center",
                  }}
                >
                  {React.cloneElement(dropdownItem.icon, {
                    sx: {
                      fontSize: item.name === "language" ? 20 : 28,
                    },
                    className: item.name === "language" ? "flag" : "",
                  })}
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  flex: 1,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    fontSize: item.name === "language" ? "14px" : "16px",
                    lineHeight: 1.2,
                  }}
                >
                  {dropdownItem.name}
                </Typography>
                {dropdownItem.description && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      fontSize: "0.875rem",
                      lineHeight: 1.4,
                    }}
                  >
                    {dropdownItem.description}
                  </Typography>
                )}
              </Box>
            </MenuItem>
            {dropdownItem.hasDividerBottom && <Divider />}
          </div>
        ))}
    </Menu>
  );
};

TopNavBar.propTypes = {
  hasBackground: PropTypes.bool,
  inputUser: PropTypes.object,
};

export default React.memo(TopNavBar);
