import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "../firebase";
import { RecaptchaVerifier } from "@firebase/auth";
import { styled, keyframes } from "@mui/material/styles";
import {
  Typography,
  Box,
  Alert,
  Button,
  CircularProgress,
  TextField,
  Grid,
  Divider,
  Collapse,
  IconButton,
  Fade,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Google,
  Email,
  Microsoft,
  Phone,
  ArrowRight,
  ArrowDropDown,
  ArrowBack,
} from "@mui/icons-material";
import { withErrorBoundary } from "react-error-boundary";
import GeneralErrorPage from "./GeneralErrorPage";
import logo from "../assets/logo-green.svg";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: "38px",
  marginBottom: "8px",
  background: "linear-gradient(90deg, #001557 0%, #0A2540 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "left",
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: "#666",
  fontSize: "16px",
  lineHeight: 1.6,
  marginBottom: "40px",
  textAlign: "left",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  padding: theme.spacing(1.5, 3),
  height: "56px",
  width: "100%",
  borderRadius: "16px",
  textTransform: "none",
  fontSize: "15px",
  fontWeight: 500,
  boxShadow: "none",
  border: "1px solid #eee",
  backgroundColor: "#fff",
  color: "#1a1a1a",
  transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
  "&:hover": {
    backgroundColor: "#f8f9fa",
    borderColor: "#51f3d2",
    transform: "translateY(-2px)",
    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.08)",
  },
}));

const slideAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

function SignInScreen() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState({
    googleLoading: false,
    buttonLoading: false,
    microsoftLoading: false,
    phoneLoading: false,
    phoneVerificationLoading: false,
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    message: "",
    open: false,
  });
  const [phoneError, setPhoneError] = useState({
    message: "",
    open: false,
  });
  const [email, setEmail] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const logos = Array(5).fill({ src: logo });
  const duplicatedLogos = [
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
  ];

  useEffect(() => {
    if (firebase.auth().currentUser) {
      /*Every sign in method will change currentUser and redirect to /login-success
       * no need to use getRedirectResult to handle successful logins
       * we only check error for each login method
       */
      window.location.href = "/login-success";
    } else if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        setEmail("");
      } else {
        setEmail(email);
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(() => {
            window.localStorage.removeItem("emailForSignIn");
            window.location.href = "/login-success";
          })
          .catch((error) => {
            console.log(error);
            setError({
              message:
                "An error occurred when verifying your login link. Please try again.",
              open: true,
            });
          });
      }
    } else {
      setIsLoading(false);
    }
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % logos.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const handlePhoneLogin = async (e) => {
    e.preventDefault();
    setPhoneError({ message: "", open: false });
    setLoader((prevState) => ({ ...prevState, buttonLoading: true }));
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "captcha-container",
        {
          size: "invisible",
          callback: (response) => {},
        },
        firebase.auth(),
      );
    }

    try {
      const result = await firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier);

      setConfirmationResult(result);
      setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
      setShowPhone(false);
      setPhoneNumber("");
    } catch (error) {
      setPhoneNumber("");
      setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
      setPhoneError({ message: error.message, open: true });
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    setPhoneError({ message: "", open: false });
    setLoader((prevState) => ({ ...prevState, buttonLoading: true }));
    try {
      await confirmationResult.confirm(confirmationCode);
    } catch (error) {
      setConfirmationCode("");
      setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
      setPhoneError({ message: error.message, open: true });
    }
  };

  const handleGoogleLogin = () => {
    setLoader({ googleLoading: true, buttonLoading: false });
    const auth = firebase.auth();
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(googleProvider).catch((error) => {
      setLoader({ googleLoading: false, buttonLoading: false });
      setError({ message: error.message, open: true });
      setIsLoading(false);
    });
  };

  const handleMicrosoftLogin = () => {
    setLoader((prevState) => ({ ...prevState, microsoftLoading: true }));
    const auth = firebase.auth();
    const provider = new firebase.auth.OAuthProvider("microsoft.com");
    auth.signInWithPopup(provider).catch((error) => {
      setLoader({ microsoftLoading: false, buttonLoading: false });
      setError({ message: error.message, open: true });
      setIsLoading(false);
    });
  };

  const handleEmailLinkLogin = (e) => {
    e.preventDefault();
    setLoader((prevState) => ({ ...prevState, buttonLoading: true }));

    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };

    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
        setSuccess(true);
        setShowEmail(false);
        setEmail("");
      })
      .catch((error) => {
        console.log(error);
        setLoader((prevState) => ({ ...prevState, buttonLoading: false }));
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleConfirmationCodeChange = (event) => {
    setConfirmationCode(event.target.value);
  };

  const renderEmailLoginForm = () => {
    return (
      <Collapse in={showEmail} collapsedSize={0}>
        <form
          onSubmit={handleEmailLinkLogin}
          style={{ width: "100%", padding: "10px 0" }}
        >
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                height: "56px",
                "& fieldset": {
                  borderColor: "#e0e0e0",
                },
                "&:hover fieldset": {
                  borderColor: "#000",
                },
              },
            }}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={loader.buttonLoading || !email}
            sx={{
              height: "56px",
              width: "100%",
              backgroundColor: "#4339F2",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#3329E2",
                boxShadow: "none",
              },
            }}
          >
            {loader.buttonLoading ? t("login_loading") : t("login_send_link")}
          </Button>
        </form>
      </Collapse>
    );
  };

  const renderPhoneLoginForm = () => {
    return (
      <Collapse in={showPhone} collapsedSize={0}>
        <form
          onSubmit={handlePhoneLogin}
          style={{ width: "100%", padding: "10px 0" }}
        >
          <TextField
            label="Phone Number"
            variant="outlined"
            type="tel"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                height: "56px",
                "& fieldset": {
                  borderColor: "#e0e0e0",
                },
                "&:hover fieldset": {
                  borderColor: "#000",
                },
              },
            }}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={loader.buttonLoading || !phoneNumber}
            sx={{
              height: "56px",
              width: "100%",
              backgroundColor: "#4339F2",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#3329E2",
                boxShadow: "none",
              },
            }}
          >
            {loader.buttonLoading ? t("login_loading") : t("login_send_code")}
          </Button>
        </form>
      </Collapse>
    );
  };

  const renderConfirmationForm = () => {
    return (
      <Collapse in={confirmationResult} collapsedSize={0}>
        <form
          onSubmit={handleCodeSubmit}
          style={{ width: "100%", padding: "10px 0" }}
        >
          {!phoneError.open && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {t("login_confirmation_code_sent")}
            </Alert>
          )}
          <TextField
            label="Confirmation code"
            variant="outlined"
            type="number"
            value={confirmationCode}
            onChange={handleConfirmationCodeChange}
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                height: "56px",
                "& fieldset": {
                  borderColor: "#e0e0e0",
                },
                "&:hover fieldset": {
                  borderColor: "#000",
                },
              },
            }}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={loader.buttonLoading || !confirmationCode}
            sx={{
              height: "56px",
              width: "100%",
              backgroundColor: "#4339F2",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#3329E2",
                boxShadow: "none",
              },
            }}
          >
            {loader.buttonLoading ? t("login_loading") : t("login_submit_code")}
          </Button>
        </form>
      </Collapse>
    );
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "#fff",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
      }}
    >
      <Grid container>
        {/* Left side - Dark Background with Content */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", md: "flex" },
            background: "#001557",
            color: "#fff",
            p: 8,
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                "radial-gradient(circle at 30% 30%, rgba(81, 243, 210, 0.2) 0%, rgba(81, 243, 210, 0.08) 45%, rgba(0, 21, 87, 0.2) 100%)",
              zIndex: 1,
            },
          }}
        >
          <Box sx={{ position: "relative", zIndex: 2 }}>
            <img
              src={logo}
              alt="logo"
              style={{
                height: "32px",
                filter: "brightness(0) invert(1)",
                marginBottom: "80px",
              }}
            />
            <Typography
              variant="h3"
              sx={{
                mb: 3,
                fontWeight: 800,
                fontSize: { md: "48px", lg: "56px" },
                lineHeight: 1.1,
                letterSpacing: "-0.02em",
              }}
            >
              {t("login_tagline")}
            </Typography>
            <Typography
              sx={{
                mb: 3,
                color: "rgba(255,255,255,0.9)",
                fontSize: "20px",
                lineHeight: 1.6,
                maxWidth: "480px",
              }}
            >
              {t("login_description")}
            </Typography>
          </Box>

          {/* Logo Carousel */}
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              height: "80px",
              mt: "auto",
            }}
          >
            <Typography
              sx={{
                color: "rgba(255,255,255,0.6)",
                fontSize: "14px",
                mb: 2,
                display: "none",
              }}
            >
              {t("login_trusted")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                animation: `${slideAnimation} 30s linear infinite`,
                alignItems: "center",
                gap: 6,
              }}
            >
              {duplicatedLogos.map((logo, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "160px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={logo.src}
                    alt="company logo"
                    style={{
                      height: "32px",
                      filter: "brightness(0) invert(1)",
                      opacity: 0.6,
                      transition: "opacity 0.2s ease-in-out",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>

        {/* Right side - Login Form */}
        <Fade in={true} timeout={1000}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              p: { xs: 2, sm: 3, md: 8 },
              maxWidth: { xs: "100%", md: "760px" },
              margin: "0 auto",
              position: "relative",
              height: { xs: "100vh", md: "auto" },
              overflowY: { xs: "auto", md: "visible" },
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <IconButton
              onClick={() => (window.location.href = "/")}
              sx={{
                alignSelf: "flex-start",
                color: "#001557",
                mb: 6,
                "&:hover": {
                  backgroundColor: "rgba(81, 243, 210, 0.08)",
                },
              }}
            >
              <ArrowBack />
            </IconButton>

            <Title variant="h1">{t("login_title")}</Title>
            <Subtitle>{t("login_subtitle")}</Subtitle>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 2,
              }}
            >
              <StyledButton
                startIcon={<Google />}
                onClick={handleGoogleLogin}
                disabled={
                  loader.googleLoading ||
                  loader.buttonLoading ||
                  confirmationResult
                }
              >
                {loader.googleLoading ? t("login_loading") : t("login_google")}
              </StyledButton>

              <StyledButton
                startIcon={<Microsoft />}
                onClick={handleMicrosoftLogin}
                disabled={
                  loader.microsoftLoading ||
                  loader.buttonLoading ||
                  confirmationResult
                }
              >
                {loader.microsoftLoading
                  ? t("login_loading")
                  : t("login_microsoft")}
              </StyledButton>
            </Box>

            <Box
              sx={{
                my: 5,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Divider sx={{ flex: 1, borderColor: "#eee" }} />
              <Typography
                sx={{ px: 3, color: "#666", fontSize: "14px", fontWeight: 500 }}
              >
                {t("login_or_continue")}
              </Typography>
              <Divider sx={{ flex: 1, borderColor: "#eee" }} />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: 2,
              }}
            >
              <StyledButton
                startIcon={<Email />}
                endIcon={showEmail ? <ArrowDropDown /> : <ArrowRight />}
                onClick={() => {
                  setShowEmail((showEmail) => !showEmail);
                  setShowPhone(false);
                }}
                disabled={loader.buttonLoading || confirmationResult}
              >
                {t("login_email")}
              </StyledButton>

              {error.open && (
                <Alert
                  severity="error"
                  sx={{
                    mt: 2,
                    width: "100%",
                    borderRadius: "16px",
                    border: "1px solid rgba(255, 77, 79, 0.16)",
                    backgroundColor: "rgba(255, 77, 79, 0.04)",
                    "& .MuiAlert-icon": {
                      color: "#ff4d4f",
                    },
                  }}
                >
                  {error.message}
                </Alert>
              )}

              {success && (
                <Alert
                  severity="success"
                  sx={{
                    mt: 2,
                    width: "100%",
                    borderRadius: "16px",
                    border: "1px solid rgba(81, 243, 210, 0.16)",
                    backgroundColor: "rgba(81, 243, 210, 0.04)",
                    "& .MuiAlert-icon": {
                      color: "#51f3d2",
                    },
                  }}
                >
                  {t("login_link_sent", { email })}
                  <br />
                  {t("login_link_sent2")}
                </Alert>
              )}

              {renderEmailLoginForm()}

              <StyledButton
                startIcon={<Phone />}
                endIcon={showPhone ? <ArrowDropDown /> : <ArrowRight />}
                onClick={() => {
                  setShowPhone((showPhone) => !showPhone);
                  setShowEmail(false);
                }}
                disabled={loader.buttonLoading || confirmationResult}
              >
                {t("login_phone")}
              </StyledButton>
            </Box>

            {phoneError.open && (
              <Alert
                severity="error"
                sx={{
                  mt: 2,
                  borderRadius: "16px",
                  border: "1px solid rgba(255, 77, 79, 0.16)",
                  backgroundColor: "rgba(255, 77, 79, 0.04)",
                  "& .MuiAlert-icon": {
                    color: "#ff4d4f",
                  },
                }}
              >
                {phoneError.message}
              </Alert>
            )}

            {renderPhoneLoginForm()}
            {renderConfirmationForm()}

            <div id="captcha-container" style={{ display: "none" }}></div>

            <Typography
              sx={{
                mt: 5,
                fontSize: "13px",
                color: "#666",
                textAlign: "center",
                lineHeight: 1.6,
                maxWidth: "400px",
                mx: "auto",
              }}
            >
              {t("login_terms")}
            </Typography>
          </Grid>
        </Fade>
      </Grid>
    </Box>
  );
}

export default withErrorBoundary(SignInScreen, {
  fallback: <GeneralErrorPage />,
});
