import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Container } from "@mui/material";
import LogoSmall from "../../assets/logo-small.svg";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Logo = styled("img")({
  width: "50px",
  height: "50px",
  marginBottom: "20px",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#fff",
  fontSize: "14px",
  lineHeight: "32px",
  "&:hover": {
    textDecoration: "underline",
  },
});

const ExternalLink = styled("a")({
  textDecoration: "none",
  color: "#fff",
  fontSize: "14px",
  lineHeight: "32px",
  "&:hover": {
    textDecoration: "underline",
  },
});

const FooterHeading = styled(Typography)({
  color: "#fff",
  fontSize: "20px",
  fontWeight: "600",
  marginBottom: "16px",
});

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: "#0A2540",
        color: "white",
        pt: 6,
        pb: 4,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Features Column */}
          <Grid item xs={12} sm={6} md={2.4}>
            <FooterHeading>{t("menu_features")}</FooterHeading>
            <Box display="flex" flexDirection="column">
              <StyledLink to="/barge-tracking">
                {t("menu_features_barge")}
              </StyledLink>
              <StyledLink to="/data-analytics">
                {t("menu_features_analytics")}
              </StyledLink>
              <StyledLink to="/rhine-waterlevels">
                {t("menu_features_rhine")}
              </StyledLink>
              <StyledLink to="/freight-rates">
                {t("menu_features_freight")}
              </StyledLink>
              <StyledLink to="/terminal-refinery-data">
                {t("menu_features_terminal")}
              </StyledLink>
              <StyledLink to="/port-refinery-utilisation">
                {t("menu_features_port")}
              </StyledLink>
              <StyledLink to="/custom-solutions">
                {t("menu_features_custom")}
              </StyledLink>
            </Box>
          </Grid>

          {/* Users Column */}
          <Grid item xs={12} sm={6} md={2.4}>
            <FooterHeading>{t("menu_users")}</FooterHeading>
            <Box display="flex" flexDirection="column">
              <StyledLink to="/charterers">
                {t("menu_users_charterers")}
              </StyledLink>
              <StyledLink to="/banks-financial">
                {t("menu_users_banks")}
              </StyledLink>
              <StyledLink to="/traders-analysts">
                {t("menu_users_traders")}
              </StyledLink>
              <StyledLink to="/operations-blending">
                {t("menu_users_operations")}
              </StyledLink>
            </Box>
          </Grid>

          {/* Company Column */}
          <Grid item xs={12} sm={6} md={2.4}>
            <FooterHeading>{t("footer_company")}</FooterHeading>
            <Box display="flex" flexDirection="column">
              <StyledLink to="/about">{t("menu_about")}</StyledLink>
              <StyledLink to="/careers">{t("footer_careers")}</StyledLink>
              <StyledLink to="/contact">{t("footer_contact")}</StyledLink>
              <StyledLink to="/privacy">{t("footer_privacy")}</StyledLink>
              <ExternalLink href="/spotbarge_terms_and_conditions_2023.pdf">
                {t("footer_terms")}
              </ExternalLink>
              <ExternalLink href="/spotbarge_code_of_conduct_2024.pdf">
                {t("footer_code")}
              </ExternalLink>
              <ExternalLink href="/spotbarge_methodology.pdf">
                {t("footer_methodology")}
              </ExternalLink>
              <ExternalLink href="/spotbarge_guideline.pdf">
                {t("footer_guideline")}
              </ExternalLink>
              <ExternalLink href="/complaints_dispute_policy.pdf">
                {t("footer_complaints")}
              </ExternalLink>
            </Box>
          </Grid>

          {/* Resources Column */}
          <Grid item xs={12} sm={6} md={2.4}>
            <FooterHeading>{t("menu_resources")}</FooterHeading>
            <Box display="flex" flexDirection="column">
              <StyledLink to="/news">{t("footer_articles")}</StyledLink>
            </Box>
          </Grid>

          {/* Follow us Column */}
          <Grid item xs={12} sm={6} md={2.4}>
            <FooterHeading>{t("footer_follow")}</FooterHeading>
            <Box display="flex" flexDirection="column">
              <ExternalLink
                href="https://www.linkedin.com/company/spotbarge/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                Linkedin
              </ExternalLink>
            </Box>
          </Grid>
        </Grid>

        {/* Logo and Copyright */}
        <Box
          sx={{ mt: 6, pt: 3, borderTop: "1px solid rgba(255,255,255,0.1)" }}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Logo src={LogoSmall} alt="Logo" />
            </Grid>
            <Grid item>
              <Typography variant="body2" color="rgba(255,255,255,0.7)">
                {t("bottom_section_copyright")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
