import React, { useState, useEffect, useContext } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Divider,
  Paper,
  IconButton,
  Container,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "./common/SpotbargeLayout";
import { UserContext } from "./AuthGuard";
import GeneralErrorPage from "./GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { alphanumericRegex } from "./QuoteForm";
import { usePost } from "../request";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    backgroundColor: '#fff',
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: '#000',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#51f3d2',
    },
  },
}));

const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,12}$/;

const UserSettings = ({isNotVerified}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [chat, setChat] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState("");
  const [bargeNames, setBargeNames] = useState([""]);
  const { t, i18n } = useTranslation();
  const [user] = useContext(UserContext);
  const [restrictedChanges, setRestrictedChanges] = useState(false);

  const [phoneValid, setPhoneValid] = useState(true);
  const [initialEmail, setInitialEmail] = useState("");

  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);
  const [chatValid, setChatValid] = useState(true);
  const [countryValid, setCountryValid] = useState(true);
  const [cityValid, setCityValid] = useState(false);
  const [bargeNamesValid, setBargeNamesValid] = useState(true);

  const post = usePost();

  useEffect(() => {
    setRestrictedChanges(user && user.role && user.role !== "none");
    if (user) {
      validateFirstNameTextField(user.firstName || "");
      validateLastNameTextField(user.lastName || "");
      validateChatTextField(user.iceChat || "");
      setCompanyName(user.companyName || "");
      setCompanyType(user.companyType || "");
      validateCountryTextField(user.country || "");
      validateCityTextField(user.city || "");
      validatePhoneTextField(user.phone || "");
      setEmail(user.email || "");
      setInitialEmail(user.email || "");

      const initialLanguage =
        user.language || localStorage.getItem("language") || "en";

      setLanguage(initialLanguage);

      setLoading(false);
    }
  }, [user]);

  const validateTextField = (value) => {
    return alphanumericRegex.test(value);
  };

  const validatePhoneTextField = (value) => {
    setPhone(value);
    // We allow empty to not make it show up red by default
    setPhoneValid(phone === "" || phoneRegex.test(value));
  };

  const validateFirstNameTextField = (value) => {
    setFirstName(value);
    setFirstNameValid(validateTextField(value));
  };

  const validateLastNameTextField = (value) => {
    setLastName(value);
    setLastNameValid(validateTextField(value));
  };

  const validateCityTextField = (value) => {
    setCity(value);
    setCityValid(validateTextField(value));
  };

  const validateCountryTextField = (value) => {
    setCountry(value);
    setCountryValid(validateTextField(value));
  };

  const validateChatTextField = (value) => {
    setChat(value);
    setChatValid(validateTextField(value));
  };

  const validateBargeNames = (bargeNames) => {
    const valid = bargeNames.every(name => name.length <= 50 && name.trim() !== "");
    setBargeNamesValid(valid);
    return valid;
  };

  const handleBargeNameChange = (index, value) => {
    const newBargeNames = [...bargeNames];
    newBargeNames[index] = value;
    setBargeNames(newBargeNames);
    validateBargeNames(newBargeNames);
  };

  const addBargeNameField = () => {
    setBargeNames([...bargeNames, ""]);
  };

  const removeBargeNameField = (index) => {
    const newBargeNames = bargeNames.filter((_, i) => i !== index);
    setBargeNames(newBargeNames);
    validateBargeNames(newBargeNames);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    if (companyType === "bargeOwner" && !validateBargeNames(bargeNames)) {
      setMessage({
        text: "Each barge name must be non-empty and no longer than 50 characters.",
        severity: "error",
      });
      setLoading(false);
      return;
    }
    try {
      const response = await post("update-user", {
        firstName,
        lastName,
        iceChat: chat,
        companyName,
        companyType,
        country,
        city,
        phone,
        language,
        bargeNames: companyType === "bargeOwner" ? bargeNames : [],
      });
      if (!response || response.error) {
        setMessage({
          text: "Failed to save user settings: " + response.error,
          severity: "error",
        });
      } else {
        setMessage({ text: response.success, severity: "success" });
        if (language) {
          localStorage.setItem("language", language);
          i18n.changeLanguage(language);
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  return (
    <SpotbargeLayout title={t("user_settings")} isNotVerified={isNotVerified}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="90vh">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
        ) : (
          <form onSubmit={handleSubmit}>
            {message.text && (
              <Alert severity={message.severity} sx={{ mb: 3 }}>
                {message.text}
              </Alert>
            )}

            <Grid container spacing={3}>
              {/* User Details */}
              <Grid item xs={12}>
                <StyledPaper>
                  <Typography variant="h6" sx={{ color: '#001557', fontWeight: 600 }}>
                    {t("user_settings_user_details")}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        required
                        fullWidth
                        label={t("user_settings_your_first_name")}
                        value={firstName}
                        onChange={(event) => validateFirstNameTextField(event.target.value)}
                        inputProps={{ maxLength: 64 }}
                        error={!firstNameValid}
                        helperText={!firstNameValid && t("user_settings_error_first_name")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        required
                        fullWidth
                        label={t("user_settings_your_last_name")}
                        value={lastName}
                        onChange={(event) => validateLastNameTextField(event.target.value)}
                        inputProps={{ maxLength: 64 }}
                        error={!lastNameValid}
                        helperText={!lastNameValid && t("user_settings_error_last_name")}
                      />
                    </Grid>
                    {initialEmail && (
                      <Grid item xs={12} sm={6}>
                        <StyledTextField
                          required
                          fullWidth
                          label="Email"
                          value={email}
                          disabled
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        fullWidth
                        label="ICE chat"
                        value={chat}
                        onChange={(event) => validateChatTextField(event.target.value)}
                        inputProps={{ maxLength: 64 }}
                        error={!chatValid}
                        helperText={!chatValid && "ICE chat can only contain letters and numbers."}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        select
                        label={t("user_settings_interface_language")}
                        onChange={(event) => handleLanguageChange(event.target.value)}
                        value={language}
                        fullWidth
                        required
                      >
                        <MenuItem value="" disabled>
                          {t("user_settings_choose_interface_language")}
                        </MenuItem>
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="nl">Nederlands</MenuItem>
                        <MenuItem value="de">Deutsch</MenuItem>
                        <MenuItem value="fr">Français</MenuItem>
                      </StyledTextField>
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>

              {/* Company Details */}
              <Grid item xs={12}>
                <StyledPaper>
                  <Typography variant="h6" sx={{ color: '#001557', fontWeight: 600 }}>
                    {t("user_settings_company_details")}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        required
                        fullWidth
                        label={t("user_settings_company_name")}
                        value={companyName}
                        onChange={(event) => setCompanyName(event.target.value)}
                        inputProps={{ maxLength: 64 }}
                        disabled={restrictedChanges}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        select
                        label={t("user_settings_type_of_company")}
                        value={companyType}
                        onChange={(event) => setCompanyType(event.target.value)}
                        fullWidth
                        required
                        disabled={restrictedChanges}
                      >
                        <MenuItem value="" disabled>
                          {t("user_settings_empty_company")}
                        </MenuItem>
                        <MenuItem value="oil">Oil</MenuItem>
                        <MenuItem value="shipping">Shipping</MenuItem>
                        <MenuItem value="broker">Broker</MenuItem>
                        <MenuItem value="bargeOwner">Barge owner</MenuItem>
                      </StyledTextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        required
                        fullWidth
                        label={t("user_settings_country")}
                        value={country}
                        onChange={(event) => validateCountryTextField(event.target.value)}
                        inputProps={{ maxLength: 64 }}
                        error={!countryValid}
                        helperText={!countryValid && t("user_settings_error_country")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        required
                        fullWidth
                        label={t("user_settings_city")}
                        value={city}
                        onChange={(event) => validateCityTextField(event.target.value)}
                        inputProps={{ maxLength: 64 }}
                        error={!cityValid}
                        helperText={!cityValid && t("user_settings_error_city")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        required
                        fullWidth
                        label={t("user_settings_your_phone_number")}
                        value={phone}
                        onChange={(event) => validatePhoneTextField(event.target.value)}
                        inputProps={{ maxLength: 16 }}
                        error={!phoneValid}
                        helperText={!phoneValid && t("user_settings_error_phone_number")}
                        disabled={!initialEmail}
                      />
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>

              {/* Barge Details */}
              {companyType === "bargeOwner" && (
                <Grid item xs={12}>
                  <StyledPaper>
                    <Typography variant="h6" sx={{ color: '#001557', fontWeight: 600 }}>
                      {t("user_settings_barge_details")}
                    </Typography>

                    {bargeNames.map((bargeName, index) => (
                      <Grid container spacing={2} key={index} alignItems="center">
                        <Grid item xs={10}>
                          <StyledTextField
                            fullWidth
                            label={t("user_settings_barge_name")}
                            value={bargeName}
                            onChange={(event) => handleBargeNameChange(index, event.target.value)}
                            inputProps={{ maxLength: 50 }}
                            error={!bargeNamesValid}
                            helperText={!bargeNamesValid && "Each barge name must be non-empty and no longer than 50 characters."}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                              onClick={() => removeBargeNameField(index)}
                              disabled={bargeNames.length === 1}
                              sx={{
                                color: '#666',
                                '&:hover': { color: '#000' },
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                            {index === bargeNames.length - 1 && (
                              <IconButton
                                onClick={addBargeNameField}
                                sx={{
                                  color: '#666',
                                  '&:hover': { color: '#000' },
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </StyledPaper>
                </Grid>
              )}

              {/* Action Button */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      loading ||
                      !phoneValid ||
                      !firstNameValid ||
                      !lastNameValid ||
                      !chatValid ||
                      !countryValid ||
                      !cityValid ||
                      (companyType === "bargeOwner" && !bargeNamesValid)
                    }
                    sx={{
                      borderRadius: '8px',
                      backgroundColor: '#51f3d2',
                      color: '#001557',
                      '&:hover': {
                        backgroundColor: '#3dd1b2',
                      },
                      '&:disabled': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                  >
                    {t("save_changes")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Container>
    </SpotbargeLayout>
  );
};

export default withErrorBoundary(UserSettings, {
  fallback: <GeneralErrorPage />,
});
