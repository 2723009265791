import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import mapVideo from "../../assets/moving-vessels.mp4";
import { keyframes } from "@mui/material/styles";
import { Link } from "react-router-dom";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutDown = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const glowPulse = keyframes`
  0%, 100% {
    text-shadow: 0 0 30px rgba(81, 243, 210, 0.1),
                 0 0 60px rgba(81, 243, 210, 0.1);
  }
  50% {
    text-shadow: 0 0 30px rgba(81, 243, 210, 0.3),
                 0 0 60px rgba(81, 243, 210, 0.2);
  }
`;

function TopSection() {
  const { t } = useTranslation();
  const words = [
    t("home_rotating_word_insights"),
    t("home_rotating_word_data"),
    t("home_rotating_word_analytics"),
    t("home_rotating_word_freight"),
  ];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsVisible(true);
      }, 600);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ bgcolor: "#fff", pt: { xs: 8, md: 10 } }}>
      {/* Hero Text Section */}
      <Container
        maxWidth="lg"
        sx={{ pt: { xs: 8, md: 0 }, pb: { xs: 4, md: 6 } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            maxWidth: "800px",
            mx: "auto",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "36px", sm: "42px", md: "56px" },
              fontWeight: 700,
              color: "#001557",
              mb: 1,
              lineHeight: 1.2,
            }}
          >
            {t("home_title_prefix")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 3,
              height: { xs: "36px", sm: "42px", md: "56px" },
              position: "relative",
            }}
          >
            <Box
              sx={{
                fontSize: { xs: "36px", sm: "42px", md: "56px" },
                fontWeight: 700,
                color: "#001557",
                minWidth: "200px",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "radial-gradient(circle at center, rgba(81, 243, 210, 0.15), transparent 70%)",
                  opacity: isVisible ? 1 : 0,
                  transition: "opacity 0.3s ease-in-out",
                },
              }}
            >
              <Box
                sx={{
                  animation: isVisible
                    ? `${fadeInUp} 0.6s ease forwards, ${glowPulse} 2s ease-in-out infinite`
                    : `${fadeOutDown} 0.6s ease forwards`,
                  background: "linear-gradient(135deg, #001557, #51f3d2)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  position: "relative",
                  whiteSpace: "nowrap",
                }}
              >
                {words[currentWordIndex]}
              </Box>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              lineHeight: 1.6,
              color: "#666",
              mb: 4,
              maxWidth: "600px",
            }}
          >
            {t("home_subtitle")}
          </Typography>

          <Button
            component={Link}
            to="/contact"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            sx={{
              bgcolor: "#51f3d2",
              color: "#001557",
              fontSize: "16px",
              fontWeight: 600,
              px: 4,
              py: 1.5,
              borderRadius: 2,
              textTransform: "none",
              boxShadow: "none",
              "&:hover": {
                bgcolor: "#3dd1b2",
                boxShadow: "none",
              },
            }}
          >
            {t("home_request_demo")}
          </Button>
        </Box>
      </Container>

      {/* Dashboard Display Section */}
      <Container maxWidth="xl" sx={{ mt: { xs: 4, md: 8 } }}>
        <Box
          sx={{
            width: "100%",
            height: { xs: "400px", md: "600px" },
            bgcolor: "#f8f9fa",
            borderRadius: 4,
            overflow: "hidden",
            boxShadow: "0 4px 24px rgba(0,0,0,0.1)",
            position: "relative",
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          >
            <source src={mapVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Container>
    </Box>
  );
}

export default TopSection;
